/* TODO ALL COLOR VARIABLES SHOULD BE REMOVE AND THEME SHOULD BE USED INSTEAD*/
/*Bootstrap Grid Configuration*/
$grid-columns: 24 !default;
/*Common*/
$toolbar-logo-width: 115px;

/*Form component*/
$form-background: #ffffff;
$form-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
  0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
$form-title-background: rgba(0, 0, 0, 0.03);
$form-title-color: rgba(0, 0, 0, 0.54);

/*Action bar component*/
$action-bar-background: #ffffff;
$action-bar-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.1),
  0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

$font: Roboto-Light;