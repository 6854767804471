/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;
@import "helpers";
@import "variables";
@import "@angular/material/theming";
// Plus imports for other components in your app.
@import "ng-viv-ux/theme";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
// currently selected theme
@import "themes/techmart.scss";

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include ng-viv-ux-theme($app-theme);

@import "../node_modules/bootstrap/scss/bootstrap.scss";

@font-face {
  font-family: "RobotoCondensed-Light";
  src: url(assets/fonts/RobotoCondensed-Light.ttf);
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'RobotoCondensed-Light';
  font-size: 16px;
  color: #231f20;
  font-weight: 300;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: mat.get-color-from-palette($mat-techmart-red, 900);
}

.text-center {
  text-align: center;
}

button:focus {
  border: 0px !important;
  outline: 0px !important;
}

.document-file {
  ngx-dropzone-preview {
    max-width: 100% !important;
    width: 100%;
  }
}

// ckeditor
figure {
  &.table {
    table {
      width: 100%;
      td,
      th {
        border: none;
      }
    }
  }
  &.image {
    img {
      width: 100%;
    }
  }
}
// Styling multi classed Heading Components
app-heading {
  .handwritten-heading {
    font-family: "handwritten", arial;
    font-size: 32px;
  }
  .bolded-heading {
    font-weight: 700;
    font-size: 32px;
  }
}
//html component without paragraph margin bottom
.info-desc{
  p{
    margin-bottom: 0;
  }
}
//backgrounds and text color
.bg-techmart-primary{
  background-color: mat.get-color-from-palette($mat-techmart-red, 900);
}
.bg-techmart-secondary{
  background-color: mat.get-color-from-palette($mat-techmart-orange, 400);
}
.text-techmart-primary{
  color: mat.get-color-from-palette($mat-techmart-red, 900);
}
.text-techmart-secondary{
  color: mat.get-color-from-palette($mat-techmart-orange, 400);
}
// google map custom icon
#deleteShape {
  #borderChild {
    background: none padding-box rgb(255, 255, 255);
    border: 1px solid #ccc;
    margin: 4px 0 0 -6px;
    cursor: pointer;
    border-radius: 0 3px 3px 0;
    :hover {
      background: none padding-box rgb(235, 235, 235);
    }
  }
  #contentChild {
    font-size: 12px;
    padding: 5px 8px 5px 8px;
  }
}

.cdk-overlay-container{
  z-index: 1100;
}

// Search box
.mat-autocomplete-panel {
  background-color: mat.get-color-from-palette($mat-techmart-grey, 25);
  max-height: 80vh !important;

  .products-title {
    font-size: rem(17px);
    color: #231f20;
    background-color: #e6e6e6;
    padding: 5px;
  }

  .search-box-text {
    font-size: rem(18px);
    line-height: 30px;
    color: #231f20;
  }

  .mat-option-text {
    img {
      max-width: 50px;
      max-height: 50px;
      width: 12%;
    }
  }
}

.mobile-search-menu-in {
  opacity: 1 !important;
  transition: opacity 0.5s;
  
}

@media (max-width: 767px) {
  
  .search-bar {
    position: fixed;
    z-index:-1;
    top: 83px;
    left: 0;
    width: 100% !important;
    padding: 10px !important;
    background-color: rgb(250, 250, 250);
    box-shadow: 0px 13px 22px -14px #5c5c5c;
    opacity: 0;

    .mat-form-field-flex {
      background: #fff !important;  
    }
  }

  .hidden-search {
    opacity: 0 !important;
    transition: opacity 0.5s;
  }
}

@media (max-width: 575px) {

  .mini-cart {

    .font-med {
      font-size: 16px;
    }
  }
  
  .search-bar {
    position: fixed;
    z-index:-1;
    top: 73px;
    left: 0;
    width: 100% !important;
    padding: 10px !important;
    background-color: rgb(250, 250, 250);
    box-shadow: 0px 13px 22px -14px #5c5c5c;
    opacity: 0;

    .mat-form-field-flex {
      background: #fff !important;  
    }
  }

  .hidden-search {
    opacity: 0 !important;
    transition: opacity 0.5s;
  }
}

.checkout {
  .mat-vertical-stepper-header {
    height: auto;
  }
  .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled="false"] {
    background-color: mat.get-color-from-palette($mat-techmart-grey, 25);
    box-shadow: inset 0px 0px 0px 1px mat.get-color-from-palette($mat-techmart-grey, 50);
  }
}

// Checkout cart
.checkout-cart-container {
  .mini-cart {
    background-color: mat.get-color-from-palette($mat-techmart-grey, 25);
  }
  .mini-cart-row {
    .image-holder {
      app-image {
        img {
          max-width: 70px !important;
          margin: 0 0 -35px 8px;
        }
      }
    }
    .cart-product-name {
      font-weight: bold;
      a {
        color: mat.get-color-from-palette($mat-techmart-grey, 500) !important;
        text-decoration: none;
        font-size: 11px;
      }
    }
    .mini-cart-remove-button {
      button {
        height: 20px;
        mat-icon {
          line-height: 10px;
        }
      }
    }
  }
  .cart-buy-button-holder {
    margin-top: -15px;
    .first-child {
      width: 100%;
      .cart-buy-button {
        color: mat.get-color-from-palette($mat-techmart-orange, 50);
        background-color: mat.get-color-from-palette($mat-techmart-orange, 400);
        border-radius: 10px;
        font-size: 15px;
        margin: 15px 0 5px 0px;
      }
    }
  }
}

//TOP RIGHT BUTTONS (PROFILE AND CART)
.mat-icon-button {
  line-height: normal !important;
}

// Mini Cart
.mini-cart-container {
  width: 450px;
  position: relative;
  z-index: 1000;

  .mini-cart-product-image{
    max-height: 90px;
    max-width: 100%;
  }
  .bordered-mini-cart {
    border: 1px solid mat.get-color-from-palette($mat-techmart-red, 500);
    border-radius: none;
    background: #fff;
    margin-top: 22px;
    .orange-border-top {
      border-top: 1px solid mat.get-color-from-palette($mat-techmart-red, 500);
    }
  }
  .shadowed-mini-cart {
    border-radius: none;
    background: #fff;
    margin-top: 22px;
    box-shadow: 0px 0px 10px black;
  }
  .lowbr {
    border-radius: 3px;
  }
  .greybg240 {
    background-color: rgb(240, 240, 240);
  }
  .mini-cart-row {
    .image-holder {
      app-image {
        img {
          max-width: 70px !important;
          margin: 0 0 -35px 8px;
        }
      }
    }
    .cart-product-name {
      font-weight: bold;
      a {
        color: mat.get-color-from-palette($mat-techmart-grey, 500) !important;
        text-decoration: none;
        font-size: 11px;
      }
    }
    .mini-cart-remove-button {
      button {
        height: 20px;
        mat-icon {
          line-height: 10px;
        }
      }
    }
  }
  .cart-buy-button-holder {
    margin-top: -15px;
    .first-child {
      width: 100%;
      .cart-buy-button {
        color: mat.get-color-from-palette($mat-techmart-orange, 50);
        background-color: mat.get-color-from-palette($mat-techmart-orange, 400);
        border-radius: 10px;
        font-size: 15px;
        margin: 15px 0 5px 0px;
      }
    }
  }
}

// Content details
.content-details-holder {
  .content-details-title {
    border-top: 1px solid mat.get-color-from-palette($mat-techmart-orange, 500);
    font-family: "handwritten";
    font-size: 40px;
    padding: 10px;
    app-slot {
      padding-top: 20px;
    }
  }
  .content-details-description {
    padding: 10px;
  }
}

// Products list
.products-list-main-container {
  .products-list {
    font-family: "RobotoCondensed-Light" !important;
    padding: 0;
    mat-card {
      padding: 0;
      box-shadow: none !important;
    }
    .product-list-img{
      transition: all 300ms ease;
    }
    .product-list-img:hover{
      transform: scale(1.1);
    }
    .products-list-title {
      padding-top: 10px;
      h4 {
        font-family: "RobotoCondensed-Light" !important;
        text-align: center;
        font-size: rem(18px);
        font-weight: 200;
      }
    }
    .products-list-price {
      font-family: "RobotoCondensed-Light" !important;
      text-align: center;
      font-size: rem(28px);
      font-weight: 600;
      color: mat.get-color-from-palette($mat-techmart-red, 900);
    }
    .products-list-button-holder {
      justify-content: center;
      .products-list-button {
        border-radius: 10px;
        padding: 0 70px 0 70px;
        font-size: 15px;
        margin: 15px 0 15px 0;
      }
    }
  }
}

// Cart Page
.cart-main-container {
  .heading-holder {
    background-color: mat.get-color-from-palette(
      $mat-techmart-orange,
      400
    ) !important;
    app-heading {
      display: flex;
      align-items: center;
      justify-content: center;
      h5 {
        color: #fef2e4 !important;
        line-height: normal !important;
        height: auto !important;
        padding-left: 0px;
        font-size: 18px;
        border-bottom: none !important;
      }
    }
  }
  .readonly-below-table {
    input {
      font-size: 1.25rem;
      font-family: RobotoCondensed-Light !important;
      font-weight: 300;
      color: mat.get-color-from-palette($mat-techmart-grey, 500) !important;
    }
    .mat-form-field-infix {
      margin: unset !important;
    }
    .mat-form-field-appearance-fill .mat-form-field-flex {
      padding: unset !important;
      padding-top: 5px !important;
    }
  }
  .readonly {
    .mat-form-field-wrapper {
      padding-bottom: 1em;
      .mat-form-field-flex {
        background-color: transparent !important;
        .mat-form-field-infix {
          border-top: unset !important;
          padding: unset !important;
          text-align: center;
          margin: -15px;
          margin-top: 8px;
        }
      }
      .mat-form-field-underline {
        display: none !important;
      }
    }
  }
  .padding-lr-50 {
    padding-left: 50px;
    padding-right: 50px;
  }
  .cart-product-name a {
    color: mat.get-color-from-palette($mat-techmart-grey, 500) !important;
    text-decoration: none;
  }
  .cart-product-name a:hover {
    text-decoration: none;
  }
  .item-holder {
    .formWrapper {
      box-shadow: none !important;
    }
    form {
      padding: 0px !important;
    }
    .arrayRow {
      .row {
        align-items: center;
      }
    }
    display: flex;
    align-items: center;
    app-image {
      box-shadow: none;
      padding: 0px;
      img {
        padding-bottom: 10px;
        max-width: 120px;
        cursor: pointer;
      }
    }
  }
  .cart-buy-button-holder {
    margin-top: -15px;
    .cart-buy-button {
      color: mat.get-color-from-palette($mat-techmart-orange, 50);
      background-color: mat.get-color-from-palette($mat-techmart-orange, 400);
      border-radius: 10px;
      padding: 0 70px 0 70px;
      font-size: 15px;
      margin: 15px 0 15px 10px;
    }
  }
}

.cart-action.mat-icon-button {
  border-radius: 0% !important;
  width: rem(30px);
  height: rem(30px);
  background-color: black;
  .mat-icon{
    margin-top: 3px;
  }
}

.cart-quantity{
  .mat-form-field-wrapper{
    padding: 0;
    width:  rem(30px) !important;
    .mat-form-field-underline::before{
      height: 0;
    }
    .mat-form-field-flex{
      padding: 0;
      height:  rem(30px) !important;
      background-color: #fff;
      border: 1px solid mat.get-color-from-palette($mat-techmart-grey, 300);
      border-radius: 2px;
      // margin-bottom: 3px;
      .mat-form-field-infix{
        border: 0;
      }
    }
  }
}

// Subscribe line
.background-orange {
  background-color: mat.get-color-from-palette($mat-techmart-orange, 400);
  .formWrapper {
    box-shadow: none !important;

    form {
      padding: 0 !important;
      background-color: mat.get-color-from-palette(
        $mat-techmart-orange,
        400
      ) !important;
    }
  }

  .subscribe-label-holder {
    color: mat.get-color-from-palette($mat-techmart-orange, 50) !important;
    display: flex;
    align-items: center;
    font-size: 18px;
  }
  .notify-ad-input {
    .mat-form-field-appearance-fill {
      .mat-form-field-flex {
        background-color: white;
        border-radius: 10px;
      }
      .mat-form-field-underline::before,
      .mat-form-field-ripple {
        display: none;
        width: calc(100% - 20px);
        margin-left: 10px;
      }
    }
  }
  .notify-ad-button-holder {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      color: mat.get-color-from-palette($mat-techmart-orange, 50);
      background-color: mat.get-color-from-palette($mat-techmart-grey, 500);
      border-radius: 10px;
      padding: 0 70px 0 70px;
      font-size: 15px;
      margin: 15px 0 15px 0;
    }
  }
}

// Main menu style
.main-menu-container {

  .mat-button-wrapper {
    font-family: RobotoCondensed-Light !important;
    text-transform: uppercase;
    font-weight: 600;
  }
  button {
    padding: 0 10px;
    &:hover {
      .mat-button-wrapper {
        color: mat.get-color-from-palette($mat-techmart-orange, 500);
      }
      -webkit-box-shadow: 0px -2px 0px rgb(247 149 29 / 75%);
      -moz-box-shadow: 0px -2px 0px rgb(247 149 29 / 75%);
      box-shadow: 0px -2px 0px rgb(247 149 29 / 75%);
      border-radius: 1%;
    }
  }
  .mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
  .mat-stroked-button:hover:not(.mat-button-disabled)
    .mat-button-focus-overlay {
    opacity: 0;
  }
}
.menu-wrapper{
  .backdrop, .navigation {
    top: 20px !important;
  }
}

.breadcrumbs-holder {
  position: relative;
  z-index: 1000;
}
.breadcrumb-menu{
  background: linear-gradient(to right, #ededed , #ffffff);
  box-shadow: -15px 0px 0px 0px rgb(255, 255, 255, 1), 15px 0px 0px 0px rgb(255, 255, 255, 1), 0px 3px 10px 0px rgb(150, 150, 150, 0.3);
  color: #231f20;
  font-family: "RobotoCondensed-Light";

  .breadcrumb-button{
    background-color: transparent !important;
  }
  button.bg-danger:hover{
    background-color: transparent !important;
  }
  .errow-icon{
    padding-top: rem(6px);
    color: #231f20
  }

  .errow-icon > mat-icon {
    font-size: 19px !important;
  }

  button > span {
    font-family: "RobotoCondensed-Light" !important;
  }
}

.bg-accent{
  background-color: mat.get-color-from-palette($mat-techmart-red, 900) !important;
}

// Main menu hambuger style
.main-menu {
  .mat-menu-content:not(:empty) {

  }
}
// Home main banner
.main-banner {
  // min-height: 440px;

  .single-slide {
    padding: 0;

    img {
      min-width: 100% !important;
    }
  }
}

// Homepage below banner headings
.below-banner-headings-holder {
  background-color: #f1f1f1;
  .border-right {
    border-right: 1px solid mat.get-color-from-palette($mat-techmart-orange, 500) !important;
  }
  .below-banner-heading {
    h5 {
      font-weight: 700;
      .heading-subtitle {
        display: block;
        font-weight: initial;
        font-size: 18px;
      }
    }
  }
}

// Homepage brands cards
.homepageBrands {
  background:rgb(250, 250, 250);
  mat-card {
    padding-top: 30px;
    padding-bottom: 30px;
    box-shadow: none !important;
    border-radius: 3px;
  }
}

.addressCards {
  background:rgb(250, 250, 250);
  
  mat-card {
    padding: 15px 10px;
    border-radius: 3px;
    justify-content: center !important;
  }
}

.location-icon {

  mat-icon {
    font-size: 50px;
    width: auto;
    height: auto;
    color: mat.get-color-from-palette($mat-techmart-red, 900);
  }
}

.card-name {
  font-size: 23px;
  font-weight: 500;
  color: #363636;
}

.card-contact-phone {
  font-size: 19px;
  font-weight: 400;
}

.min-card-height {
  min-height: 145px;
}

.add-address-button {
  box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.2), 0px 1px 5px rgba(0, 0, 0, 0.141), 0px 1px 8px rgba(0, 0, 0, 0.122) !important;
  
  span {
    font-size: 21px;
  }
  mat-icon {
    width: auto;
    height: auto !important;
    font-size: 60px;
    position: relative;
    top: 5px;
  }
}

// One-imaged carousel parallax styling
.parallax-content {
  app-html {
    p:first-of-type {
      font-family: "handwritten", arial;
      font-size: 70px;
      border-top: 2px solid mat.get-color-from-palette($mat-techmart-orange, 500) !important;
      padding-top: 15px;
    }
    p:nth-of-type(2) {
      font-family: RobotoCondensed-Light;
      font-size: 70px;
      font-weight: bold;
      margin-bottom: 0rem !important;
    }
    a {
      color: #fef2e4;
      background-color: #f8a53f;
      border-radius: 10px;
      padding: 5px 70px 8px 70px;
      font-size: 18px;
    }
    a:hover {
      text-decoration: none;
    }
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    position: absolute;
    width: 100%;
  }
}
.parallax-slight-right {
  app-html {
    justify-content: end !important;
    width: 75% !important;
  }
}
// Homepage carousel products
.homepage-carousel-main {
  mat-card {
    padding: 5px !important;
  }
}

.orange-border {
  border-top: 1px solid mat.get-color-from-palette($mat-techmart-orange, 500);
}
.orange-thick-border {
  border-top: 2px solid mat.get-color-from-palette($mat-techmart-orange, 500);
}
.product-details-main-container {
  // Product details
  .details-select-size {
    form {
      padding: 0px !important;
    }
    .formWrapper {
      box-shadow: none;
    }
  }
  .details-title {
    font-size: 20px;
    padding-top: 50px;
  }
  .details-price {
    font-size: 26px;
    font-weight: 700;
  }
  .details-button-holder {
    .quantity-input {
      .mat-form-field-infix {
        padding-top: 0px !important;
        padding-bottom: 20px !important;
      }
    }
    .details-button {
      color: mat.get-color-from-palette($mat-techmart-orange, 50);
      background-color: mat.get-color-from-palette($mat-techmart-orange, 400);
      border-radius: 10px;
      font-size: 20px;
      margin: 15px 0 15px 0;
    }
  }
  .details-shipping-button {
    border: 2px solid mat.get-color-from-palette($mat-techmart-orange, 300) !important;
    .mat-button-wrapper {
      color: mat.get-color-from-palette($mat-techmart-grey, 300);
    }
    mat-icon {
      font-size: 40px;
      margin-top: -20px;
      color: mat.get-color-from-palette($mat-techmart-orange, 500);
    }
  }
  .details-image-holder {
    padding-left: 0px;
    img {
      width: 100%;
    }
  }
  .details-product-description {
    .handwritten-heading {
      font-size: 26px;
    }

    app-slot {
      padding-top: 20px;
    }
  }
  .details-desc-nmcl-holder {
    .details-nmcl-key::after {
      content: "\003A \00a0 \00a0";
    }
  }
  .details-heading-last-viewed {
    padding-top: 20px;
  }
  .details-carousel-last-viewed {
    mat-card {
      background: transparent;
      padding: 5px;
      .carousel-product-title {
        height: 75px;
      }
    }
  }
}
.details-desc-nmcl-holder {
  box-shadow: -15px 0px 0px 0px rgba(256, 256, 256, 1), 15px 0px 0px 0px rgba(256, 256, 256, 1), 0px -10px 0px 0px rgba(256, 256, 256, 1), 0px 3px 10px 0px rgba(150, 150, 150, 0.3);
  margin-bottom: 1rem;
}

.homepageProductsCarousel {
  .swiper {
    padding: 5px;
    padding-bottom: 50px;

    .swiper-zoom-container {
      // border: 1px solid #dee2e6;
      // padding: 10px;
      // border-radius: 3px;
    }
  }

  // img {
  //   padding-left: 10px;
  //   padding-right: 10px;
  // }
}

.carousel-product {
  font-family: RobotoCondensed-Light !important;
  mat-card {
    box-shadow: none !important;
  }

  app-image {
    height: 200px !important;
    display: block;

    picture {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 170px;

      img {
        width: auto !important;
        max-height: 100% !important;
        max-width: 90% !important;
        transition: all .2s ease-in-out;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  .carousel-product-title {
    height: 60px;
    cursor: pointer;

    h4 {
      font-size: 17px;
      font-weight: 400;
      font-family: RobotoCondensed-Light !important;
    }
  }
  .carousel-product-price {
    font-size: 20px;
    font-weight: 700;
  }
  .carousel-product-button-holder {
    justify-content: center;
    .carousel-product-button {
      color: mat.get-color-from-palette($mat-techmart-orange, 50);
      background-color: mat.get-color-from-palette($mat-techmart-orange, 400);
      border-radius: 10px;
      padding: 0 70px 0 70px;
      font-size: 15px;
      margin: 15px 0 15px 0;
    }
    .carousel-last-viewed-button {
      color: #fff;
      background-color: mat.get-color-from-palette($mat-techmart-red, 900);
      border-radius: 6px;
      padding: 0 70px 0 70px;
      font-size: 15px;
      margin: 15px 0 15px 0;
      transition: all ease 0.5s;
    }
    .carousel-last-viewed-button:hover {
      background-color: #d30d13 ;
      transition: all ease 0.5s;
    }
  }
}

.background-primary {
  background-color: mat.get-color-from-palette($app-accent);
}
// Footer

.footer-container {
  .footer-menu-holder {
    .footer-menu-links {
      display: flex;
      a {
        text-transform: uppercase;
        font-size: 14px;
        color: #fff;
      }
      a:hover {
        text-decoration: none !important;
      }
    }
  }
}

@media (max-width: 1200px) {
  .cart-main-container {
    .padding-lr-50 {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .parallax-content {
    app-html {
      p:first-of-type,
      p:nth-of-type(2) {
        font-size: 40px;
      }
      a {
        font-size: 15px;
      }
    }
  }
  .footer-container {
    app-slot {
      padding-bottom: 30px;
    }
  }
  .product-list {
    padding-left: 15px;
  }
  .products-list-row {
    margin-right: 0px;
  }
}

.red-border-bottom {
  border-bottom: 1px solid mat.get-color-from-palette($mat-techmart-red, 900) !important;
}

@media (max-width: 991px) {
  .cart-main-container {
    .cart-buy-button-holder {
      .cart-buy-button {
        margin: 15px 0 15px 0px;
      }
    }
    .item-holder {
      align-items: center;
    }
    .heading-holder {
      align-items: center;
    }
    .image-holder {
      app-image {
        img {
          max-width: 70px !important;
        }
      }
    }
    .cart-product-name {
      font-weight: bold;
      a {
        font-size: 15px;
      }
    }
  }
  .mini-cart-container {
    .bordered-mini-cart {
      margin-top: unset;
    }
  }
  .content-details-title,
  .content-details-description {
  }
  .below-banner-headings-holder {
    padding-left: unset;
    padding-right: unset;
    padding: 10px;
    .border-right {
      border-right: none !important;
      border-bottom: 1px solid
        mat.get-color-from-palette($mat-techmart-orange, 500) !important;
      margin-bottom: 15px;
    }
  }
  .orange-border-bottom {
    border-bottom: 1px solid mat.get-color-from-palette($mat-techmart-orange, 500);
  }
  .orange-border {
    margin-left: 0px;
  }
  .product-details-main-container {
    .details-title {
      padding-top: 20px;
    }
    .details-select-size {
      justify-content: center;
    }

    .shipping-button-holder {
      text-align: center;
    }
    .details-button-holder {
      justify-content: center;
    }
    .details-product-description,
    .details-desc-nmcl-holder {
      padding-left: 15px;
    }
    .details-title,
    .details-price {
      text-align: center !important;
    }
    .details-image-holder {
      padding-left: 15px;
    }
  }
  .products-list-row {
    margin-right: 0px;
  }
}

@media (max-width: 768px) {
  .main-banner {
    min-height: 300px;
  }

  .cart-main-container {
    .heading-holder {
      app-heading {
        h5 {
          font-size: 15px;
        }
      }
    }
    .arrayRow {
      border-top: none !important;
      padding: unset !important;
    }
    .item-holder {
      justify-content: space-evenly;
    }
    .heading-holder {
      justify-content: center;
    }
  }

  .parallax-content {
    app-html {
      p:first-of-type {
        padding-top: 5px;
        margin-bottom: 0px;
      }
      p:first-of-type,
      p:nth-of-type(2) {
        font-size: 26px;
      }
      a {
        font-size: 12px;
      }
    }
  }
  .background-orange {
    .subscribe-label-holder {
      justify-content: center;
    }
  }
}
@media only screen and (max-width: 576px) {
  .filter-toggle,
  .order-create {
    app-slot {
      width: 100%;
      button {
        width: 100%;
        margin: 0;
      }
    }
  }
  .resize-text{
    font-size: rem(15px) !important;
  }
}

@media only screen and (max-width: 500px) {
  .parallax-content {
    app-html {
      p:first-of-type {
        padding-top: 5px;
        margin-bottom: -5px;
      }
      p:first-of-type,
      p:nth-of-type(2) {
        font-size: 20px;
      }
      a {
        font-size: 10px;
      }
    }
  }
  .resize-text{
    font-size: rem(12px) !important;
  }
}

@media only screen and (min-width: 350px) {
  .push-to-left {
    position: relative;
    top: auto;
    left: auto;
    height: 30px;
  }
  .mat-autocomplete-panel {
    top: 10px !important;
  }
}

@media only screen and (min-width: 576px) {
  .push-to-left {
    position: relative;
    top: auto;
    left: auto;
    height: 50px;
  }
}

@media only screen and (min-width: 768px) {
  .push-to-left {
    position: relative;
    top: auto;
    left: -20px;
  }
  .main-menu-fix {
    position: absolute !important;
    height: 100px !important;
  }
  .mat-autocomplete-panel {
    top: 23px !important;
  }
}

@media only screen and (min-width: 992px) {
  .push-to-left {
    position: relative;
    top: auto;
    left: -35px;
  }
}

@media only screen and (min-width: 1200px) {
  .push-to-left {
    position: relative;
    top: auto;
    left: -45px;
  }
  
}

@media only screen and (min-width: 1400px) {
  .push-to-left {
    position: relative;
    top: auto;
    left: -85px;
  }
}

// Homescreen techmart cy
.search-bar, .subscribe-form-footer {
  
  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-wrapper > .mat-form-field-flex {
    border: 1px solid rgb(230, 230, 230);
    border-radius: 0px;
    background-color: rgb(250, 250, 250);
    padding-top: 0;
    padding-right: 6px;
    align-items: center;
    line-height: 25px;

    .mat-form-field-infix {
      font-family: RobotoCondensed-Light !important;
      padding-bottom: 0;

      .mat-input-element{
        position: relative;
        bottom: 3px;
      }
    }
  }

  .mat-form-field-suffix {
    background-color: rgb(237, 28, 36);
    padding: 4px;
    border-radius: 3px;
    color: white;
  }
}

.action-links{
  div.h4{
    font-size: rem(28px) !important;
  }
  .on-hover:hover{
    color: rgb(237, 28, 36);
  }
}

.cart-icon .mat-icon-button .mat-badge-content {
  right: -3px !important;
  top: -5px !important;
}

.email-offers {
  background-color: rgb(250, 250, 250);
}

.swiper .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: rgb(0, 0, 0);
  opacity: 1;
}

.swiper .swiper-pagination-bullet-active {
  background-color: rgb(255, 0, 0);
}

.grid-box {
  width: 150px;
  height: 150px;
  background-color: rgb(246, 246, 246);
}

.top-products-text {
  font-size: 35px;
}

.product-sale-price {
  color: rgb(237, 28, 36);
  font-size: 28px;
  font-weight: 700;
  font-family: RobotoCondensed-Light !important;
}

.product-base-price {
  color: $dark-disabled-text;
  font-size: rem(28px);
  font-weight: 700;
  text-decoration: line-through;
  font-family: RobotoCondensed-Light !important;
}

.product-details-base-price {
  color: $dark-disabled-text;
  font-size: rem(16px);
  font-weight: 300;
  text-decoration: line-through;
  font-family: RobotoCondensed-Light !important;
}

.without-base-price {
  justify-content: center !important;
}

.footer {
  background-color: #fff;
}

.footer-container-techmart {
  background-color: rgb(230, 230, 230);

  .footer-menu-holder {
    .footer-menu-links {
      display: flex;
      a {
        text-transform: titlecase;
        font-size: 17px;
        font-weight: 400;
        color: #231f20;
      }
      a:hover {
        text-decoration: none !important;
      }
    }
  }
  .footer-links{
    div.h5{
      font-size: rem(22px);
      color: #231f20;
    }
    .phone-number{
      font-size: rem(30px);
    }
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

swiper, .single-slide {
  animation: fadeIn 3s;
}
.swiper-slide {
  img {
    max-width: 100% !important;
    max-height: 100%;
    width: auto !important;
  }
}

// Cart page - techmart cy
.cart-grid {
  background-color: rgb(250, 250, 250);

  .no-cart-items {
    font-size: 30px;
    font-weight: 500;
  }
}

.cart-texts {
  font-weight: 700;
  line-height: 25.5px;
  font-size: rem(17px);
  a {
    color: #1a1a18;
  }
}

.font-big {
  font-size: 20px;
}

.header-grid {
  z-index: 500 !important;
  box-shadow: -15px 0px 0px 0px rgb(255, 255, 255, 1), 15px 0px 0px 0px rgb(255, 255, 255, 1), 0px 3px 10px 0px rgb(150, 150, 150, 0.3);
  width: 100%;
}

.mini-cart-header {
  font-size: 26px;
  font-weight: 500;
}

.overlay-panel {
  justify-content: end;
  width: calc(400px + 0.5vw);
}

// Product details page
// .main-image.swiper {
//   border: 1px solid #e2e2e2;
//   padding: 1.5rem !important;
//   margin-bottom: 0.5rem !important;
// }

.thumbnail .swiper-slide {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.details-heading {
  font-size: 26px;
}

.details-price {
  font-size: 40px;
  color: mat.get-color-from-palette($app-primary, 900);
}

.details-split-header {
  font-size: 17px;
}

.details-buttons {
  font-size: 17px;
  font-weight: 200;
}

.product-details-order {
  font-size: 17px;
  font-weight: 200;
  background-color: #ed1c24 !important;
  transition: all ease 0.5s;

  mat-icon {
    position: relative;
    top: 5px;
    margin-right: 10px;
    color: #fff !important;
  }
}
.product-details-order:hover {
  background-color: #d30d13 !important;
  transition: all ease 0.5s;
}

.delivery {
  background: url("./assets/img/percentBg.png") no-repeat;
  background-size: 90px 90px;
  background-position: start;
  font-size: 11px;
  height: 100px;

  .delivery-icon {
    margin-left: 10px;
  }

  .delivery-text {
    margin-left: 5px;
    margin-top: -5px;
  }
}

.product-sticky-overview {
  &.opened {
    opacity: 1;
  }

  position: fixed;
  overflow: hidden;
  opacity: 0;
  transition: all ease 0.3s;
  top: 81px;
  background: linear-gradient(to right, #ededed, #ffffff);
  z-index: 999;
  width: 100%;
  box-shadow: -15px 0px 0px 0px rgb(255, 255, 255, 1), 15px 0px 0px 0px rgb(255, 255, 255, 1), 0px 3px 10px 0px rgb(150, 150, 150, 0.3);

  .overview-img {
    background-color: #fff;
    border: 1px solid #d5d5d5;
  }
  .heavy-title {
    font-weight: 900 !important;
    margin: 0 !important;
  }
  .product-base-price {
    font-weight: 300;
    font-size: 1rem;
  }
  .img-height {
    max-height: 75px;
    width: auto;
  }
}

@media only screen and (min-width: 350px) {

  .product-details-holder{
    
    .details-image-holder img {
      max-height: 225px;
      width: auto !important;
    }
    
    .details-heading {
      text-align: center !important;
    }
  }
  .light-grey-shadow {
    box-shadow: -15px 0px 0px 0px rgb(255, 255, 255, 1), 15px 0px 0px 0px rgb(255, 255, 255, 1), 0px 3px 10px 0px rgb(150, 150, 150, 0.3);

    .add-to-cart-button, .badge-holder {
      padding-left: 0 !important;
      padding-right: 0 !important;
      text-align: center !important;
    }
  }
  .info-footer-icons {
    text-align: center !important;
  }

  .order-success-product-details {
    font-size: 16px;
  }
}

@media only screen and (min-width: 576px) {
  
  .product-details-holder{

    .details-image-holder img {
      max-height: 280px;
    }
  }
  
  .checkout-totals {
    font-size: 20px;
  }

  .order-success-product-details {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) {
  
  .product-details-holder{

    .details-image-holder img {
      max-height: 350px;
      width: auto !important;
    }
    .details-heading {
      text-align: left !important;
    }
  }

  .swiper-wrapper {
    align-items: center;

    .swiper-slide {

      img {
        max-width: 100% !important;
        max-height: 600px;
        width: auto !important;
      }
    }

  }
  
  .gallery-thumbnails {
    
    img {
      max-height: 100px !important;
    }
  }
  
  .order-success-product-details {
    font-size: 21px;
  }
}

@media only screen and (min-width: 992px) {
  
  .product-sticky-overview {

    .img-height {
      max-height: 45px !important;
      width: auto;
    }
    button {
      font-size: 11px;
    }
  }

  .product-details-holder{

    .details-image-holder img {
      max-height: 260px;
      width: auto !important;
    }
  }
  
  .carousel-last-viewed-button {
    padding: 0 50px !important;
  }
  
}
@media only screen and (min-width: 1200px) {
  
  .product-sticky-overview {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .img-height {
      max-height: 50px;
      width: auto;
    }
    button {
      font-size: 13px;
    }

  }
}
@media only screen and (min-width: 1400px) {
  
  .product-sticky-overview {

    .img-height {
      max-height: 7vh;
      width: auto;
    }
    button {
      font-size: 17px;
    }
  }

  .product-details-holder{

    .details-image-holder img {
      max-height: 400px;
      width: auto !important;
    }
  }
}

.info-footer {

  img {
    max-height: 60px;
    width: auto;
    max-width: 100%;
  } 
}

.details-image-holder {

  img {
    max-height: 450px;
    width: auto !important;
  }
}

.carousel-product {

  .product-base-price {
    font-size: 15px;
    text-align: right;
    padding-top: 5px;
    padding-right: 5px;
  }
}

.products-list-main-container {
  
  .product-base-price {
    font-size: 15px;
    text-align: right;
    padding-top: 5px;
    padding-right: 5px;
  }
}

.single-slide img {
  max-width: 100%;
  width: auto !important;
  cursor: pointer;
}


.skipButton {
  &:hover {
    background: none !important;
  }
  padding: 0 !important;
  background: none;
}

.cdk-overlay-container {
  .mat-snack-bar-container {
    position: absolute !important;
    top: 70px !important;
    right: 50px !important;
  }
}

.border-bottom-white {
  border-bottom: 1px solid #fff;
}

.bordered-white-bg {
  padding: 3px;
  border: 1px solid rgb(230, 230, 230);
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(230, 230, 230, 0.5);
}

.white-bg-shadow {
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(150, 150, 150, 0.7);
}

.success-data-container {
  border-radius: 5px;

  .light-grey-shadow {
    box-shadow: -15px 0px 0px 0px rgb(255, 255, 255, 1), 15px 0px 0px 0px rgb(255, 255, 255, 1), 0px 7px 10px -6px rgb(150, 150, 150, 0.3);
  }
}
.success-page{
  
    mat-icon {
      height: 30px;
      padding-top: 9px;
    }
}

.uppercase {
  text-transform: uppercase;
}

.mat-vertical-content {
  padding: 6px 24px 24px 24px !important;
}

.price-on-top {
  text-align: justify;
  position: relative;
  top: 14px;
  left: 3px;
}
.price-on-bottom {
  position: relative;
  top: 5px;
}

.profile-menu {
  background-color: #fff;
  padding: 10px;
  border: 1px solid rgb(230, 230, 230);
  box-shadow: 0px 0px 10px 0px rgba(200, 200, 200, 0.6);

  mat-icon {
    font-size: 19px;
    color: mat.get-color-from-palette($mat-techmart-red, 900);
    height: auto !important;
    padding-top: 12px;
    margin-left: 10px;
  }
}

.shadow-bottom {
  box-shadow: 0px 15px 20px -10px rgb(100, 100, 100, 0.35);
}

.promo-text {
  position: absolute;
  top: 10px;
  left: 0px;
  background-color: mat.get-color-from-palette($mat-techmart-red, 900);
  color: #fff;
  clip-path: polygon(0px 0px, 100% 0px, 83.93% 98.13%, 0px 100%);
  font-weight: 400;
}

.product-details-promo-text {
  position: absolute;
    top: 235px;
    left: 100px;
    background-color: #ed1c24;
    color: #fff;
    clip-path: polygon(0px 0px, 100% 0px, 83.93% 98.13%, 0px 100%);
    font-weight: 400;
    z-index: 1000;
}

.add-related-button {

  mat-icon {
    position: relative;
    top: 5px;
    left: -5px;
  }
}

.related-products-table {

  .mat-elevation-z8 {
    box-shadow: none;
  }

  img {
    max-width: 100px;
    max-height: 100px;
  }

  mat-icon {
    font-weight: bold;
  }
}

.nmcl-icon {
  max-width: 100px;
  max-height: 100px;
}